$(document).on('click', '#send', function(e){
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required')
    
    inputs.each(inputCheck)
    e.preventDefault()

    if(!form.find('.error').length){
        var user_name = $.trim($('#user_name').val()),
            phone = $.trim($('#phone')[0].mask.unmaskedValue),
            message = $.trim($('#message').val()),
            ajax = 'add_question',
            results = {
                user_name,
                phone,
                message
            }
        
        btn.attr('disabled', true)

        if($('.product-single').length){
            console.log($('.product-single'));
            var id = $('.product-single').data('id');
            ajax = 'add_product_question';
            results.product = id;
        }
        
        sendAjax(ajax, results, function(){
            btn.attr('disabled', false)
            inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})