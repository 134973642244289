$('.dashboard-courses, .dashboard-recommend-courses').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    slide: '.course-card',
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
})