$('.buy-course').click(function(){
    var elem = $(this),
        id = elem.data('id');

    elem.attr('disabled', true);
    requestAjax('ajax_create_bill', id, function(res){
        location.href = res;
    });
})

$('.pay-bill').click(function(){
    var elem = $(this),
        id = elem.data('id');

    elem.attr('disabled', true);
    requestAjax('ajax_pay_bill', id, function(){
        location.href = '/success/?bill_id=' + id;
    });
})

$('.cancel-bill').click(function(){
    var elem = $(this),
        id = elem.data('id');

    elem.attr('disabled', true);
    requestAjax('ajax_cancel_bill', id, function(){
        history.back();
    });
})