if($('#register_form').length){

    $('#register_confirm, .send-again').click(function(e){
        var elem = $(this),
            form = $('#register_form');
    
        e.preventDefault();
        
        form.find('.required').each(inputCheck);
    
        if(form.find('.form-group.error').length == 0){
            var results = pickInputs(form);
    
            elem.attr('disabled', true);
    
            sendAjax('check_user', results, function(res){
                window.location.href = '/dashboard';
    
            }, function(res){
                displayErrors(res.text_error);
                elem.attr('disabled', false);
            });
        }
    })

}