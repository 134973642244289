$(document).on('click', '#members_save', function(){
    var elem = $(this),
        id = elem.data('id'),
        users = [];

    $('.user-item').each(function(){
        var item = $(this),
            user = item.data('id');

        users.push(user);        
    })

    elem.attr('disabled', true);
    requestAjax('save_members', {id, users}, function(){
        window.location.reload();
    })
})