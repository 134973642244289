$(document).ready(function () {
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        centerMode: false,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

    $('.gallery-salon-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
        responsive: [{
            breakpoint: 768,
            settings: {
                centerMode: false,
                variableWidth: false, 
            }
        }
    ]
    })


    $(document).on('click', '.arrows[data-slider] div', function(){
        var elem = $(this),
            parent = elem.parent(),
            slider = parent.data('slider'),
            slider = $('.' + slider)[0].slider,
            index = elem.index();
        
        slider = parent.data('slider'),
        slider = $('.' + slider);

        if(index){
            slider.slick('slickNext');
        }else{
            slider.slick('slickPrev');
        }
    })

});