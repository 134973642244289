function select_user(){
    var items = $('.select-user:not(.loaded)');

    items.each(function(){
        var elem = $(this),
            input = elem.find('.field'),
            roles = elem.data('roles'),
            onselect = elem.data('onselect'),
            onremove = elem.data('onremove'),
            hidden = elem.find('input[type="hidden"]'),
            block = elem.find('.selected'),
            register = elem.find('.select-user-register'),
            register_btn = elem.find('.open-select-user-register');

        elem.addClass('loaded');

        function updateList(){
            var results = [];
            elem.find('.user-item').each(function(){
                var elem = $(this),
                    id = elem.data('id');
        
                results.push(id);
            })
        
            results = results.join(', ');
            hidden.val(results);

            elem.find('.remove-item').click(function(){
                var elem = $(this),
                    item = elem.parents('.user-item');

                item.remove();
                if(onremove){
                    window[onremove]()
                }
                updateList();
            })

        }

        updateList();

        requestAjax('get_select_user_items', roles, function(res){
            var users = JSON.parse(res);

            input.autocomplete({
                appendTo: input.parent(),
                minLength: 0,
                source: function(request, response) {
                    var filtred = [],
                        request = request.term.toLowerCase();
        
                    $.each(users, function(key, value){
                        var selected = hidden.val().split(', '),
                            user_id = value.id.toString();
        
                        if(selected.indexOf(user_id) == -1){
                            var title = (value.name + value.login).toLowerCase();
        
                            if(title.indexOf(request) != -1){
                                filtred.push(value);
                            }
                        }
                    });
                    
                    var results = filtred.slice(0, 5);
        
                    response(results);

                },
                classes: {
                    "ui-autocomplete": "search-autocomplete select-dropdown"
                },
                select: function( event, ui ) {
                    var item = ui.item,
                        id = item['id'];

                    elem.addClass('loading');

                    requestAjax(
                        'get_user_item',
                        {
                            user_id: id,
                            removable: 1
                        },
                        function(res){
                            elem.removeClass('loading');
                            block.append(res);

                            if(onselect){
                                window[onselect]()
                            }

                            input.val('');
                            input.blur();
                            updateList();
                            return false;  

                        }
                    );
    
                },
                
            }).data('ui-autocomplete')._renderItem = function(ul,item){		
                var name = item.name,
                    id = item.id;
        
                return $("<li>").addClass('prevent').append(name).attr('data-id', id).appendTo(ul);
            };
    
            input.focus(function(){
                input.autocomplete( 'search', input.val() );
            });

        });

        if(register.length){
            var close_register = register.find('.su-register-close'),
                confirm = register.find('.select-user-register-btn');

            register_btn.click(function(){
                register_btn.hide();
                register.addClass('show').show();
                register.find('input:not([name="surname"]):not([name="email"])').addClass('required');
            })

            close_register.click(function(){
                register_btn.show();
                register.hide();
                register.removeClass('show').find('input:not([name="surname"]):not([name="email"])').removeClass('required show');
                register.find('input').each(resetInput);
            })

            confirm.click(function(e){
                var inputs = register.find('.required');

                inputs.each(inputCheck);
                e.preventDefault();
            
                if(!register.find('.error').length){
                    var results = pickInputs(register);
            
                    confirm.attr('disabled', true);
                    elem.addClass('loading');
            
                    sendAjax('add_manager_user', results, function(res){

                        requestAjax(
                            'get_user_item',
                            {
                                user_id: res.id,
                                removable: 1
                            },
                            function(res){
                                elem.removeClass('loading');
                                block.append(res);
                                confirm.attr('disabled', false);
                                close_register.click();
    
                                elem.find('.remove-item').click(function(){
                                    var elem = $(this),
                                        item = elem.parents('.user-item');
                    
                                    item.remove();
                                    if(onremove){
                                        window[onremove]()
                                    }
                                    updateList();
                                })
    
                                updateList();
                            }
                        );

                    },function(res){
                        process_error(res);
                        confirm.attr('disabled', false);
                    });
                }
            })
        }

    })
}

select_user();