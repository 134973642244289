if($('#restore_form').length){

    $('#restore_confirm, .send-again').click(function(e){
        e.preventDefault();
        var elem = $(this),
            wrapper = $('.form-wrapper'),
            type = $('input[name="type"]:checked').val(),
            fields = $('.email-form'),
            form = $('#restore_form'),
            codeInput = $('.code-input'),
            codeWrapper = $('.confirm-code');
    
        if(type == 'phone'){
            fields = $('.phone-form');
        }
    
        fields.find('.required').each(inputCheck);
    
        if(!fields.hasClass('error')){
            elem.attr('disabled', true);
            var results = pickInputs(fields);
    
            sendAjax(
                'restore_user',
                results,
                function(res){                    
                    form.find('.message-block').show();
                },
                function(res){
                    elem.attr('disabled', false);
                    displayErrors(res.text_error);                    
                }
            )
    
        }
    
    })
    
    $('#new_pass_confirm').click(function(e){
        var elem = $(this),
            form = $('#new-pass-form-wrapper'),
            wrapper = $('.auth-content');
    
        e.preventDefault();
    
        form.find('.required').each(inputCheck);
    
        if(form.find('.error').length == 0){
            elem.attr('disabled', true);
            sendAjax(
                'restore_password',
                pickInputs(form, true),
                function(res){
    
                    wrapper.find('.message-block').show();
    
                    var timerBlock = $('#redirect-timer'),
                        num        = 5;
    
                    timerBlock.html(num);
            
                    var index = num;
                    var timerId = setInterval(function() {
                        timerBlock.html(--index);
                    }, 1000);
                
                    setTimeout(function() {
                        clearInterval(timerId);
                    }, num*1000);
    
                    setTimeout(function(){
                        window.location.href = '/login?restore=success';
                    }, 5000);
                },
                function(res){
                    elem.attr('disabled', false);
                    displayErrors(res.text_error);   
                    addNotif('Ошибка', 'Что-то пошло не так', 'error');                   
                }
            )
    
        }
    })

}