function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll')
    }else{
        $('header.site-header').removeClass('scroll')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$(document).click(function (e) {
    var menu = $('.menu-group')

    if ( !menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show')
    }
})

$('.toggle-search').click(function(){
    $('.header-search').stop().slideToggle(300);
})

$(document).mouseup(function (e){
    var div = $(".user-block");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
        div.find('.user-dropdown').stop().fadeOut(300);
    }
});

$('.user-info').click(function(){
    var elem = $(this),
        dropdown = elem.next();

    dropdown.stop().fadeToggle(300);
})

$(document).on('click', '.toggle-menu', function(){
    $('.mobile-menu').stop().fadeToggle(300)
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-menu').stop().fadeOut(300)
})

$('.sidebar-title').click(function(){
    var elem = $(this),
        menu = elem.next('ul');

    menu.stop().slideToggle(300);
})




$( ".menu-item-has-children" ).mouseover(function() {
        $( this ).children('.sub-menu').addClass('active');
  });


  $( ".sub-menu" ).mouseout(function() {
    $( ".sub-menu" ).removeClass('active');
  });



  

