var players = $('.player');

players.each(function(){
    var elem = $(this),
        id = elem.attr('id'),
        video = elem.data('id');
        
    // new Plyr('#' + id, {
    //     settings: ['captions', 'quality', 'speed', 'loop']
    // });

    new Vimeo.Player(id, {
        id: video
    });
})